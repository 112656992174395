import { useStaticQuery, graphql } from "gatsby"

export const SiteConfig = () => {
  const { sanitySiteConfig } = useStaticQuery(
    graphql`
      query SiteMetaData {
        sanitySiteConfig {
          footerText
          title
          description
          url
          phone
          address
          email
          openingHours
          facebookPath
          instagramUsername
          linkedinPath
          totalDealerWebsiteId
          totalDealerBusinessId
          footerImage {
            asset {
              url
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          baseFont
          logo {
            asset {
              url
            }
          }
          favicon {
            asset {
              url
            }
          }
          _rawMainNavigation(resolveReferences: {maxDepth: 10})
          _rawFooterNavigation(resolveReferences: {maxDepth: 10})
          legalText
          layout {
            component
            _key
          }
          variables {
            name
            value
          }
        }
      }
    `
  )
  return sanitySiteConfig
}
